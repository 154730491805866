import BasePlugin from '../BasePlugin'

export default class UnpackingInvestmentExpensesBudgetRecordsCommand extends BasePlugin {
  async execute () {
    let InvestmentExpensesBudgetRecordsId = []
    let gridInDashboard = this.context.getDashboardComponents()['component_85a2cc8b-e3f5-4175-b3b4-32cafdfef2b9'][0]
    Object.values(gridInDashboard.$refs.table.selected).forEach(function (item) {
      InvestmentExpensesBudgetRecordsId.push(item.id)
    })
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/UnpackingInvestmentExpensesBudgetRecordsCommand`,
      { 'InvestmentExpensesBudgetRecordsId': InvestmentExpensesBudgetRecordsId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
      this.context.$msgbox.close()
      this.context.getParentContext().getDashboardComponents()['component_9671665d-6dd9-43b2-8e25-0bac61f6e094'][0].loadData()
      setTimeout(() => {
        let text = 'Распаковка прошла успешно!'
        this.context.$msgbox({
          type: 'info',
          message: text
        })
      }, 2500)
    })
  }
}
